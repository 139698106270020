.text{
font-size: calc(20px + 3vw);
color: whitesmoke;
}

.text-smaller{
    font-size: calc(20px + 0.8vw);
    color: whitesmoke;
}

.time{
    font-size: calc(20px + 4vw);
    color: whitesmoke;
}

.background{
    background-color: rgba(100, 95, 95, 0.705);
}