.box {
  min-height: 15vh;
  margin-bottom: 1vh;
  font-size: 20px;
}

.prayertime-title {
  font-size: calc(20px + 1.2vw);
}
.prayertime {
  font-size: calc(20px + 2vw);
}

.prayertime-title-small {
  font-size: calc(16px + 0.3vw);
}

.ayet {
    font-size: calc(16px + 1vw);
  }

