.main {
  background-image: url("../public/clouds_dark.jpg");
  height: 100vh;
  background-size: cover;
  background-position: center;
}

@keyframes scrollText {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}
